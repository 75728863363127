<!--

      This view displays the page, which allows to define a new password after
      resetting the password via E-Mail.

 -->

<template>
  <SignInUpPage action="newpassword">
    <div class="signinup-content">
      <div>Neues Passwort setzen</div>
      <div>Bitte gib ein neues Passwort ein.</div>
      <div class="fields">
        <div class="input-field">
          <Icon name="lockOpenOutline" class="icon-input" />
          <ion-input ref="pw1" @ionChange="setPassword($event)" placeholder="Passwort (mind. 8 Zeichen)" pattern="password" inputmode="password" type="password" data-cy="register-password-1"></ion-input>
        </div>
        <div class="input-field">
          <Icon name="lockOpenOutline" class="icon-input" />
          <ion-input ref="pw2" v-on:keyup.enter="this.doPasswordReset()" @ionChange="setPassword2($event)" placeholder="Passwort wiederholen" pattern="password" inputmode="password" type="password" data-cy="register-password-2"></ion-input>
        </div>
      </div>
      <div class="btn-primary" type="submit" @click="this.doPasswordReset()" data-cy="submit">Weiter</div>
    </div>
  </SignInUpPage>
</template>

<script>
import SignInUpPage from '@/components/SignInUpPage.vue'
import { IonInput } from '@ionic/vue'

export default {
  name: 'newpassword',
  components: {
    SignInUpPage,
    IonInput
  },
  data () {
    return {
      password: null,
      password2: null
    }
  },
  methods: {
    setPassword (event) {
      if (event.target.value !== '') {
        this.password = event.target.value
      }
    },
    setPassword2 (event) {
      if (event.target.value !== '') {
        this.password2 = event.target.value
      }
    },
    doPasswordReset () {
      if (!this.password || !this.password2) {
        this.$store.dispatch('showNotification', { message: 'Bitte fülle beide Passwort-Felder aus.', type: 'Error' })
      }
      if (this.password && this.password2 && this.password != this.password2){
        this.$store.dispatch('showNotification', { message: 'Huch. Du hast zwei unterschiedliche Passwörter eingegeben. Bitte versuche es noch einmal.', type: 'Error' })
        this.emptyPasswordFields()
      } else if (this.password && this.password.length < 8) {
        this.$store.dispatch('showNotification', { message: 'Dein Passwort ist zu kurz. Es muss mindestens 8 Zeichen haben.', type: 'Error' })
        this.emptyPasswordFields()
      } else {
        const details = {
          url: this.$store.state.slug,
          data: {
            new_password: this.password
          },
          method: 'PATCH'
        }
        this.$store.dispatch('apiSend', details).then( result => {
          if ( result.status === 'success') {
            this.$store.dispatch('showNotification', { message: 'Dein Passwort wurde erfolgreich geändert. Bitte logge dich jetzt ein.', type: 'Success' })
            this.$store.dispatch('goTo', 'login')
          }
        })
      }
    },
    emptyPasswordFields () {
      this.$refs.pw1.value = ''
      this.$refs.pw2.value = ''
    }
  }
}
</script>
